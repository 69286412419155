<template>
  <v-app-bar
    id="app-bar"
    style="border-bottom: 1px solid #37474b !important"
    flat
    :style="
      dark
        ? 'border-bottom: 1px solid #37474b !important; background-color: #182e32 !important'
        : 'border-bottom: 1px solid #ebebeb !important; background-color: #fff !important'
    "
    app
    height="62"
    class="appBar"
  >
    <!-- <v-alert
      border="bottom"
      colored-border
      type="warning"
      elevation="2"
      max-width="300px"
      style="right:50%;top:-5px;position:fixed"
    >
      Remmaining Patient : 100
      <br />
      Remaining Bills : 100
      <br />
      Remaining Days : 30
    </v-alert> -->
    <v-list
      transition="slide-x-transition"
      dense
      nav
      class="ma-0 pa-0"
      width="75"
      v-if="!drawer"
    >
      <v-list-item class="ma-0 pa-0">
        <v-btn
          style="padding: 31px 0px !important"
          class="ma-0"
          min-width="100%"
          min-height="100%"
          color="primary"
          tile
          depressed
          to="/"
        >
          <span class="pt-1 mt-n2">
            <v-img :src="logoSamll" />
          </span>
        </v-btn>
      </v-list-item>
    </v-list>

    <v-btn class="mx-3" elevation="0" fab small @click="setDrawer(!drawer)">
      <v-icon v-if="drawer"> mdi-view-quilt </v-icon>

      <v-icon v-else> mdi-menu </v-icon>
    </v-btn>

    <v-toolbar-title class="hidden-sm-and-down font-weight-light mx-10">
      <!-- {{
        $route.name == "vouchers"
          ? $route.params.type == "1"
            ? $t("vouchers.receiptVouchers")
            : $t("vouchers.paymentVouchers")
          : $t($route.name)
      }} -->

      {{ $t("hello") + " ..  " }}
      <span class="primary--text">
        {{ userName }}
      </span>
    </v-toolbar-title>

    <v-spacer />

    <!-- <v-text-field color="secondary" hide-details style="max-width: 165px">
      <template v-if="$vuetify.breakpoint.mdAndUp" v-slot:append-outer>
        <v-btn class="mt-n2" elevation="1" fab small>
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
      </template>
    </v-text-field> -->

    <dashboard-core-settings />

    <!-- <v-btn class="ml-1" min-width="0" color="grey lighten-1" text to="/">
      <v-icon>mdi-view-dashboard</v-icon>
    </v-btn> -->

    <v-menu
      bottom
      left
      offset-y
      origin="top right"
      transition="slide-x-transition"
    >
      <template v-slot:activator="{ attrs, on }">
        <v-btn
          :class="rtl ? 'ml-3' : 'mr-3'"
          min-width="0"
          color="grey lighten-1"
          text
          elevation="0"
          fab
          small
          v-bind="attrs"
          v-on="on"
        >
          <!-- {{ $t("hello") + "  " + userName }} -->
          <img width="25" :src="avatar" />
        </v-btn>
      </template>

      <v-list :tile="false" nav>
        <div>
          <v-list-item>
            <v-list-item-title>
              <v-btn text to="/changePassword">
                {{ $t("users.changePassword") }}
              </v-btn>
            </v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title>
              <v-btn text to="/auth">
                {{ $t("appBar.logout") }}
              </v-btn>
            </v-list-item-title>
          </v-list-item>
        </div>
      </v-list>
    </v-menu>

    <!-- <v-btn class="ml-2" min-width="0" text to="/pages/user">
      <v-icon>mdi-account</v-icon>
    </v-btn> -->
  </v-app-bar>
</template>

<script>
// Components
// import { VHover, VListItem } from "vuetify/lib";

import DashboardCoreSettings from "../components/Settings";
import { mapState, mapMutations } from "vuex";
export default {
  name: "DashboardCoreAppBar",
  components: {
    DashboardCoreSettings,
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    userName: localStorage.fullname,
    rtl: localStorage.rtl === "true",
    logoSamll: require("../assets/logo-small.png"),
    avatar: require("../assets/avatar.png"),
    dark: localStorage.dark === "true",
  }),

  watch: {},
  computed: {
    ...mapState(["drawer"]),
  },

  methods: {
    ...mapMutations({
      setDrawer: "SET_DRAWER",
    }),
  },
};
</script>
<style>
.appBar > .v-toolbar__content {
  padding: 4px 0;
}
</style>
