<template>
  <v-main
    :style="
      this.$vuetify.theme.dark ? 'background: #192628;' : 'background: #f0fdff'
    "
    :class="$vuetify.breakpoint.smAndDown ? 'mymain' : ''"
  >
    <v-alert
      text
      type="warning"
      icon="mdi-alert"
      v-if="demo && demo.isDemo && demo.remainingTime > 0"
    >
      <span> {{ $t("ThisIsDemo") }} </span>
      {{ $t("youCanAdd") }}
      <b> {{ demo.remainingPatients }} </b> {{ $t("RemainingPatientsCount") }}
      <b> {{ demo.remainingBills }} </b> {{ $t("RemainingBillsCount") }}
      <b> {{ demo.remainingTime }} </b> {{ $t("RemainingDaysCount") }}
    </v-alert>

    <v-alert
      text
      type="error"
      v-if="demo && demo.isDemo && demo.remainingTime <= 0"
    >
      <span> {{ $t("demoExpired") }} </span>
      <a text href="https://kf.iq" target="_blank">
        {{ $t("alKafeelCenter") }}
      </a>
    </v-alert>
    <p
      class="font-weight-medium px-3 py-0 mt-5 mb-n1 text-center primary--text"
      style="font-size: 1.5rem; line-height: 2rem"
      v-if="$route.name != 'Dashboard'"
    >
      {{
        $route.name == "vouchers"
          ? $route.params.type == "1"
            ? $t("vouchers.receiptVouchers")
            : $t("vouchers.paymentVouchers")
          : $t($route.name)
      }}
    </p>

    <router-view />
    <!-- <dashboard-core-footer /> -->
  </v-main>
</template>

<script>
export default {
  name: "DashboardCoreView",
  data() {
    return {
      demo: null,
    };
  },
  created() {
    this.$axios.get("Dashboard/GetDemo").then((response) => {
      this.demo = response.data.data;
    });
  },
  components: {},
};
</script>
<style>
.v-main__wrap {
  padding-right: 45px;
  padding-left: 45px;
  padding-top: 20px;
}
.mymain .v-main__wrap {
  padding: 0;
}
.mainAr .v-main__wrap {
  padding: 0;
}
.main .v-main__wrap {
  padding: 0;
}
.theme--light.v-text-field--outlined fieldset {
  border-color: #ebebeb;
}
.theme--light.v-text-field--outlined {
  background-color: #f1fdff;
}

.theme--dark.v-data-table,
.theme--dark.v-card,
.theme--dark.v-toolbar.v-sheet,
.theme--dark.v-tabs > .v-tabs-bar,
.theme--dark.v-window-item,
.theme--dark.v-list,
.theme--dark.v-expansion-panels .v-expansion-panel.main,
.theme--dark.v-tabs-items,
.theme--dark.v-text-field--solo > .v-input__control > .v-input__slot,
.theme--dark.v-expansion-panels .v-expansion-panel {
  background-color: #1b2e32 !important;
}

.theme--dark .dx-datagrid,
.theme--dark .dx-filemanager .dx-filemanager-toolbar,
.theme--dark .dx-toolbar {
  background-color: #1b2e32 !important;
}

/* .v-pagination__item--active {
  background-color: #fff !important;
}

.theme--dark.v-pagination .v-pagination__navigatio,
.theme--dark.v-pagination .v-pagination__item {
  background: #25363a;
} */

.theme--dark.v-text-field--outlined fieldset {
  border-color: #37474b;
}
/* .theme--dark.v-text-field--outlined {
  background-color: #f1fdff;
} */

.v-text-field--outlined {
  border-radius: 6px !important;
}

.search {
  width: 250px;
  transition: width 0.5s ease-out !important;
}
.seachXs {
  width: 50px;
  transition: width 1.5s ease-out !important;
}
.search.v-input--is-focused {
  width: 500px;
}
.theme--light.v-sheet--outlined {
  border: 1px solid #ebebeb;
}

.theme--dark.v-sheet--outlined {
  border: 1px solid #37474b;
}

.v-otp-input .v-input .v-input__control .v-input__slot {
  background: #70d7e7 !important;
}

.v-otp-input input {
  color: white !important;
}
</style>
