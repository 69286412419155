<template>
  <v-navigation-drawer
    v-model="drawer"
    id="core-navigation-drawer"
    :right="$vuetify.rtl"
    app
    width="250"
    v-bind="$attrs"
    style="background-color: #182e32"
    :class="$vuetify.rtl ? 'customeNav' : 'customeNavL'"
  >
    <div class="customclass">
      <v-list dense nav class="ma-0 pa-0">
        <v-list-item class="ma-0 pa-0">
          <!-- <v-img :src="$vuetify.rtl ? logoAr : logoEn" /> -->

          <v-btn
            style="padding: 32px 0px 29px !important"
            class="ma-0"
            min-width="100%"
            min-height="100%"
            color="primary"
            tile
            depressed
            to="/"
          >
            <span class="pt-1 mt-n2">
              <v-img :src="$vuetify.rtl ? logoAr : logoEn" />
            </span>
          </v-btn>
        </v-list-item>
      </v-list>

      <v-list nav>
        <template v-for="(item, i) in myItems.filter((f) => f.visible)">
          <v-list-group
            exact
            :key="i"
            :prepend-icon="item.icon"
            v-if="item.children"
            v-model="item.active"
            :class="$vuetify.rtl ? 'customeGroup' : 'customeGroupL'"
          >
            <template v-slot:activator>
              <v-list-item-content style="color: #d2e3e1">
                <v-list-item-title> {{ $t(item.title) }}</v-list-item-title>
              </v-list-item-content>
            </template>

            <template v-slot:prependIcon>
              <v-icon>
                {{ item.icon }}
              </v-icon>
            </template>

            <template v-slot:appendIcon>
              <v-icon> mdi-chevron-down </v-icon>
            </template>

            <v-list-item
              active-class="primary white--text"
              color="white"
              v-for="(subItem, ind) in item.children.filter((f) => f.visible)"
              :key="ind"
              :to="subItem.to"
            >
              <v-list-item-icon
                :style="
                  $vuetify.rtl ? 'margin-left: 10px' : 'margin-right: 10px'
                "
              >
              </v-list-item-icon>
              <v-list-item-title class="mx-n7">
                <v-icon>mdi-circle-small</v-icon>
                {{ $t(subItem.title) }}
              </v-list-item-title>
            </v-list-item>
          </v-list-group>

          <v-list-item
            v-else
            :to="item.to"
            :key="'e' + i"
            active-class="primary"
            color="white"
          >
            <v-list-item-icon
              :style="$vuetify.rtl ? 'margin-left: 10px' : 'margin-right: 10px'"
            >
              <v-icon>
                {{ item.icon }}
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
          </v-list-item>
        </template>
      </v-list>
    </div>
  </v-navigation-drawer>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "DashboardCoreDrawer",

  props: {
    expandOnHover: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    drawer1: true,
    logoEn: require("../assets/logo-en-in.png"),
    logoAr: require("../assets/logo-ar-in.png"),
    items: [
      {
        icon: "mdi-view-dashboard-outline",
        title: "Dashboard",
        group: "dashboard",
        children: [
          {
            title: "groups.groups",
            to: "/groups",
            role: "0",
          },
          {
            icon: "mdi-account",
            title: "users.users",
            to: "/users",
            role: "0",
          },
          {
            title: "branches.branches",
            icon: "mdi-clipboard-outline",
            to: "/branch",
            role: "1",
          },

          {
            title: "doctors.doctors",
            icon: "mdi-format-font",
            to: "/doctors",
            role: "6",
          },
          {
            title: "country.countries",
            icon: "mdi-clipboard-outline",
            to: "/countries",
            role: "10",
          },
          {
            title: "accountTree.accountTree",
            icon: "mdi-clipboard-outline",
            to: "/accountTree",
            role: "14",
          },
          {
            title: "settings.settings",
            icon: "mdi-cog",
            to: "/settings",
            role: "63",
          },
        ],
      },

      {
        title: "services.services",
        icon: "mdi-hand-heart-outline",
        group: "services",
        role: "49",
        children: [
          {
            title: "services.servicesList",
            icon: "mdi-chart-bubble",
            to: "/services",
            role: "49",
          },
          {
            title: "services.serviceCharges",
            icon: "mdi-map-marker",
            to: "/serviceCharge",
            role: "53",
          },
        ],
      },

      {
        title: "tests.tests",
        icon: "mdi-heart-cog-outline",
        //role: "69",
        group: "tests",
        children: [
          {
            title: "tests.testParticular",
            to: "/testParticular",
            role: "71",
          },
          {
            title: "tests.testTemplate",
            to: "/testTemplate",
            role: "75",
          },

          {
            title: "tests.testHeader",
            to: "/testHeader",
            role: "79",
          },
          {
            title: "tests.testBody",
            to: "/testBody",
            role: "84",
          },
          {
            title: "tests.testProfileCharge",
            to: "/testProfileCharge",
            role: "89",
          },
          {
            title: "tests.testParticularUser",
            to: "/testParticularUser",
            role: "110",
          },
        ],
      },
      {
        title: "prescriptions.prescriptions",
        icon: "mdi-clipboard-text-multiple-outline",
        role: "36",
        group: "prescription",
        children: [
          {
            title: "prescriptions.prescriptionsList",
            icon: "mdi-chart-bubble",
            to: "/prescriptionGroup",
          },
          {
            title: "prescriptions.prescriptionsItems",
            icon: "mdi-map-marker",
            to: "/prescriptionItems",
            role: "40",
          },
        ],
      },
      {
        title: "patients.patients",
        icon: "mdi-account-group-outline",
        group: "patients",
        role: "28",

        children: [
          {
            title: "patients.patientList",
            icon: "mdi-chart-bubble",
            to: "/patients",
          },
          {
            title: "patients.patientRegistration",
            icon: "mdi-map-marker",
            to: "/patientregistration",
            role: "29",
          },
          {
            title: "categories.categories",
            icon: "mdi-map-marker",
            to: "/patientCategories",
            role: "112",
          },
        ],
      },
      {
        title: "appointments.appointments",
        icon: "mdi-clipboard-list-outline",
        to: "/appointments",
        role: "18",
      },
      {
        title: "patients.patientSchedule",
        //icon: "mdi-clipboard-list-outline",
        //icon: "mdi-account-box-multiple-outline",
        icon: "mdi-calendar-account-outline",
        to: "/patientschedule",
        role: "19",
      },
      {
        title: "tests.testsLab",
        icon: "mdi-flask-outline",
        //role: "69",
        group: "tests",
        children: [
          {
            title: "tests.testProfileRequest",
            to: "/testProfileRequest",
            role: "102",
          },
          {
            title: "tests.testRequest",
            to: "/testRequest",
            role: "93",
          },
        ],
      },

      {
        title: "bills.bills",
        icon: "mdi-file-document-edit-outline",
        to: "/bills",
        role: "24",
      },
      {
        title: "vouchers.vouchers",
        icon: "mdi-cash-multiple",
        to: "/vouchers",
        role: "57",
      },

      {
        title: "reports.reports",
        icon: "mdi-text-box-search-outline",
        role: "69",
        group: "reports",
        children: [
          {
            title: "reports.billReport",
            icon: "mdi-chart-bubble",
            to: "/billReport",
            role: "70",
          },
        ],
      },
    ],
  }),
  watch: {
    drawer1() {
      this.drawer1 = true;
    },
  },
  created() {
    var childs = this.myItems.filter((item) => {
      return (
        item.children &&
        item.children.some((it) => {
          return it.to == this.$route.path;
        })
      );
    });
    if (childs && childs.length > 0) {
      childs[0].active = true;
    }
  },
  computed: {
    ...mapState(["barColor", "barImage"]),
    drawer: {
      get() {
        return this.$store.state.drawer;
      },
      set(val) {
        this.$store.commit("SET_DRAWER", val);
      },
    },

    computedItems() {
      var indexes = [];
      var items = JSON.parse(JSON.stringify(this.items));

      for (var i = 0; i < items.length; i++) {
        var item = items[i];
        if (item.role) {
          if (!this.isInRole(item.role)) {
            items[i] = null;
          }
        }
        var childRoles = [];
        var child = item.children;
        if (child) {
          for (var j = 0; j < child.length; j++) {
            var subItem = child[j];

            if (subItem.role) {
              if (!this.isInRole(subItem.role)) {
                child[j] = null;
              } else {
                childRoles.push(subItem.role);
              }
            }
          }
          item.children = child.filter((f) => {
            return f != null && f != "null";
          });
        }

        if (childRoles.length == 0 && !item.role) {
          indexes.push(i);
        }
      }

      for (var m = 0; m < indexes.length; m++) {
        items.splice(indexes[m], 1);
      }

      return items.filter((f) => {
        return f != null && f != "null";
      });
    },
    profile() {
      return {
        avatar: true,
      };
    },

    myItems() {
      var list = [
        {
          icon: "mdi-view-dashboard-outline",
          title: "Dashboard",
          group: "dashboard",
          visible:
            this.$store.getters.isInRole(0) ||
            this.$store.getters.isInRole(1) ||
            this.$store.getters.isInRole(6) ||
            this.$store.getters.isInRole(10) ||
            this.$store.getters.isInRole(14) ||
            this.$store.getters.isInRole(63),
          children: [
            {
              title: "groups.groups",
              to: "/groups",
              role: "0",
              visible: this.$store.getters.isInRole(0),
            },
            {
              icon: "mdi-account",
              title: "users.users",
              to: "/users",
              role: "0",
              visible: this.$store.getters.isInRole(0),
            },
            {
              title: "branches.branches",
              icon: "mdi-clipboard-outline",
              to: "/branch",
              role: "1",
              visible: this.$store.getters.isInRole(1),
            },

            {
              title: "doctors.doctors",
              icon: "mdi-format-font",
              to: "/doctors",
              role: "6",
              visible: this.$store.getters.isInRole(6),
            },
            {
              title: "country.countries",
              icon: "mdi-clipboard-outline",
              to: "/countries",
              role: "10",
              visible: this.$store.getters.isInRole(10),
            },
            {
              title: "accountTree.accountTree",
              icon: "mdi-clipboard-outline",
              to: "/accountTree",
              role: "14",
              visible: this.$store.getters.isInRole(14),
            },
            {
              title: "settings.settings",
              icon: "mdi-cog",
              to: "/settings",
              role: "63",
              visible: this.$store.getters.isInRole(63),
            },
          ],
        },

        {
          title: "services.services",
          icon: "mdi-hand-heart-outline",
          group: "services",
          visible:
            this.$store.getters.isInRole(49) &&
            (this.$store.getters.isInRole(65) ||
              this.$store.getters.isInRole(66) ||
              this.$store.getters.isInRole(67)),
          children: [
            {
              title: "services.servicesList",
              icon: "mdi-chart-bubble",
              to: "/services",
              visible: this.$store.getters.isInRole(49),
            },
            {
              title: "services.serviceCharges",
              icon: "mdi-map-marker",
              to: "/serviceCharge",
              visible: this.$store.getters.isInRole(53),
            },
          ],
        },
        {
          title: "tests.tests",
          icon: "mdi-heart-cog-outline",
          visible:
            (this.$store.getters.isInRole(71) ||
              this.$store.getters.isInRole(75) ||
              this.$store.getters.isInRole(79) ||
              this.$store.getters.isInRole(84) ||
              this.$store.getters.isInRole(89) ||
              this.$store.getters.isInRole(110)) &&
            this.$store.getters.isInRole(83),
          group: "tests",
          children: [
            {
              title: "tests.testParticular",
              to: "/testParticular",
              visible: this.$store.getters.isInRole(71),
            },
            {
              title: "tests.testTemplate",
              to: "/testTemplate",
              visible: this.$store.getters.isInRole(75),
            },

            {
              title: "tests.testHeader",
              to: "/testHeader",
              visible: this.$store.getters.isInRole(79),
            },
            {
              title: "tests.testBody",
              to: "/testBody",
              visible: this.$store.getters.isInRole(84),
            },
            {
              title: "tests.testProfileCharge",
              to: "/testProfileCharge",
              visible: this.$store.getters.isInRole(89),
            },
            {
              title: "tests.testParticularUser",
              to: "/testParticularUser",
              visible: this.$store.getters.isInRole(110),
            },
          ],
        },
        {
          title: "prescriptions.prescriptions",
          icon: "mdi-clipboard-text-multiple-outline",
          visible: this.$store.getters.isInRole(36),
          group: "prescription",
          children: [
            {
              title: "prescriptions.prescriptionsList",
              icon: "mdi-chart-bubble",
              to: "/prescriptionGroup",
              visible: true,
            },
            {
              title: "prescriptions.prescriptionsItems",
              icon: "mdi-map-marker",
              to: "/prescriptionItems",
              visible: this.$store.getters.isInRole(40),
            },
          ],
        },
        {
          title: "patients.patients",
          icon: "mdi-account-group-outline",
          group: "patients",
          visible: this.$store.getters.isInRole(28),
          children: [
            {
              title: "patients.patientList",
              icon: "mdi-chart-bubble",
              to: "/patients",
              visible: true,
            },
            {
              title: "patients.patientRegistration",
              icon: "mdi-map-marker",
              to: "/patientregistration",
              role: "29",
              visible: this.$store.getters.isInRole(29),
            },

            {
              title: "categories.categories",
              icon: "mdi-map-marker",
              to: "/patientCategories",
              role: "112",
              visible: this.$store.getters.isInRole(112),
            },
          ],
        },
        {
          title: "appointments.appointments",
          icon: "mdi-clipboard-list-outline",
          to: "/appointments",
          visible: this.$store.getters.isInRole(18),
        },
        {
          title: "patients.patientSchedule",
          icon: "mdi-calendar-account-outline",
          to: "/patientschedule",
          visible:
            this.$store.getters.isInRole(19) &&
            (this.$store.getters.isInRole(65) ||
              this.$store.getters.isInRole(66) ||
              this.$store.getters.isInRole(67)),
        },
        {
          title: "tests.testsLab",
          icon: "mdi-flask-outline",
          visible:
            (this.$store.getters.isInRole(102) ||
              this.$store.getters.isInRole(93)) &&
            this.$store.getters.isInRole(83),
          group: "tests",
          children: [
            {
              title: "tests.testProfileRequest",
              to: "/testProfileRequest",
              visible: this.$store.getters.isInRole(102),
            },
            {
              title: "tests.testRequest",
              to: "/testRequest",
              visible: this.$store.getters.isInRole(93),
            },
          ],
        },

        {
          title: "bills.bills",
          icon: "mdi-file-document-edit-outline",
          to: "/bills",
          visible: this.$store.getters.isInRole(24),
        },
        {
          title: "vouchers.vouchers",
          icon: "mdi-cash-multiple",
          to: "/vouchers",
          visible: this.$store.getters.isInRole(57),
        },

        {
          title: "reports.reports",
          icon: "mdi-text-box-search-outline",
          visible: this.$store.getters.isInRole(69),
          group: "reports",
          children: [
            {
              title: "reports.billReport",
              icon: "mdi-chart-bubble",
              to: "/billReport",
              visible: this.$store.getters.isInRole(70),
            },
            {
              title: "reports.patientsReport",
              icon: "mdi-chart-bubble",
              to: "/patientsReport",
              visible: this.$store.getters.isInRole(120),
            },
          ],
        },
      ];
      return list;
    },
  },

  methods: {
    mapItem(item) {
      return {
        title: item.title,
        icon: item.icon,
        group: item.group,
      };
    },
  },
};
</script>
<style>
.customeNav > div {
  overflow-y: hidden;
  /* direction: ltr; */
}
.customeNavL > div {
  overflow-y: hidden;
  /* direction: rtl; */
}

.customeGroup > div > .v-list-group__header__prepend-icon {
  margin-left: 10px !important;
}
.customeGroupL > div > .v-list-group__header__prepend-icon {
  margin-right: 10px !important;
}

.v-ripple__container {
  display: none !important;
}
</style>
<style scoped>
.v-icon.v-icon {
  color: #d2e3e1;
}

.v-list-item {
  min-height: 45px;
  color: #d2e3e1 !important;
}
.white--text {
  color: white !important;
}

.white--text > div > i {
  color: white !important;
}
</style>
<style lang="sass">
@import '~vuetify/src/styles/tools/_rtl.sass'

#core-navigation-drawer
  .v-list-group__header.v-list-item--active:before
    opacity: .24

    .v-list-item
      &__icon--text,
      &__icon:first-child
        justify-content: center
        text-align: center
        width: 20px

        +ltr()
          margin-right: 24px
          margin-left: 12px !important

        +rtl()
          margin-left: 24px
          margin-right: 12px !important

    .v-list--dense
      .v-list-item
        &__icon--text,
        &__icon:first-child
          margin-top: 10px
          color: white

    .v-list-group--sub-group
      .v-list-item
        +ltr()
          padding-left: 8px

        +rtl()
          padding-right: 8px

      .v-list-group__header
        +ltr()
          padding-right: 0

        +rtl()
          padding-right: 0

        .v-list-item__icon--text
          margin-top: 19px
          order: 0
          color: white

        .v-list-group__header__prepend-icon
          order: 2
          color: white
          +ltr()
            margin-right: 8px

          +rtl()
            margin-left: 8px
</style>
