<template>
  <vue-splash
    :show="true"
    :logo="logo"
    title=""
    color="#b4dddc"
    :size="300"
    :fixed="true"
  />
</template>

<script>
import YourLogo from "../assets/newlogo.svg";

export default {
  name: "YourMagnificentVueComponent",
  computed: {
    logo() {
      return YourLogo;
    },
  },
};
</script>
