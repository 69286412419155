import Vue from "vue";
import Vuex from "vuex";
import router from "../router";
import axios from "axios";
import jwt_decode from "jwt-decode";
Vue.use(Vuex);

export const store = new Vuex.Store({
  state: {
    drawer:
      localStorage.getItem("drawer") == null
        ? true
        : localStorage.getItem("drawer") == "true",

    mini:
      localStorage.getItem("mini") == null
        ? true
        : localStorage.getItem("mini") == "true",
    isDrawerOpen: false,
    idToken: localStorage.getItem("token"),
    userId: localStorage.getItem("userId"),
    fullname: localStorage.getItem("fullname"),
    expiration: localStorage.getItem("expiration"),
    roles: localStorage.getItem("roles"),
    hostName: localStorage.getItem("hostName"),
    overlay: false,
  },
  mutations: {
    updateDrawer(state, value) {
      state.isDrawerOpen = value;
    },
    toggleDrawer(state) {
      state.isDrawerOpen = !state.isDrawerOpen;
    },
    SET_DRAWER(state, payload) {
      state.drawer = payload;
      localStorage.setItem("drawer", payload);
    },
    SET_Mini(state, payload) {
      state.mini = payload;
      localStorage.setItem("mini", payload);
    },

    authUser(state, userData) {
      state.idToken = userData.token;
      state.userId = userData.userId;
      state.fullname = userData.fullname;
      state.expiration = userData.expiration;
      state.roles = userData.roles;
      state.isSuperAdmin = userData.isSuperAdmin;
      state.doctorGuid = userData.doctorGuid;
      state.hostName = userData.hostName;
    },
    clearAuth(state) {
      state.idToken = null;
      state.userId = null;
      state.fullname = null;
      state.expiration = null;
      state.roles = null;
      state.isSuperAdmin = null;
      state.doctorGuid = null;
      state.hostName = null;
    },
    overlay(state, overlay) {
      state.overlay = overlay;
    },
  },
  getters: {
    isLoading(state) {
      return state.overlay;
    },
    isInRole: (state) => (role) => {
      var is = jwt_decode(state.idToken).role.some((s) => s == role);
      return is;
    },
    userName(state) {
      return jwt_decode(state.idToken).unique_name;
    },
    hostName(state) {
      return jwt_decode(state.idToken).HostName;
    },
  },
  actions: {
    login({ commit }, userData) {
      localStorage.setItem("token", userData.accessToken);
      localStorage.setItem("userId", userData.id);
      localStorage.setItem("fullname", userData.userName);
      localStorage.setItem("expiration", userData.expiration);
      localStorage.setItem("roles", userData.roles);
      localStorage.setItem("isSuperAdmin", userData.isSuperAdmin);
      localStorage.setItem("doctorGuid", userData.doctorGuid);
      localStorage.setItem("hostName", userData.hostName);
      //var jsonString = localStorage.getItem("userD");
      // var retrievedObject = JSON.parse(jsonString);
      commit("authUser", {
        token: userData.accessToken,
        userId: userData.id,
        fullname: userData.userName,
        expiration: userData.expiration,
        roles: userData.roles,
        isSuperAdmin: userData.isSuperAdmin,
        doctorGuid: userData.doctorGuid,
        hostName: userData.hostName,
      });
      axios.defaults.headers.get["Authorization"] =
        "Bearer " + userData.accessToken;
      router.push({
        name: "Dashboard",
      });
    },
    overlay({ commit }, overlay) {
      commit("overlay", overlay);
    },

    logout({ commit }) {
      commit("clearAuth");
      //localStorage.clear();

      var lang = localStorage.getItem("lang")
        ? localStorage.getItem("lang")
        : "ar";

      // console.log(lang);

      if (this.$vuetify) {
        localStorage.setItem("dark", this.$vuetify.theme.dark);
        this.$vuetify.rtl = lang == "ar" ? true : false;
      }
      if (this.$i18n) {
        this.$i18n.locale = lang;
      }

      localStorage.setItem("lang", lang);

      localStorage.setItem("token", null);
      localStorage.setItem("userId", null);
      localStorage.setItem("fullname", null);
      localStorage.setItem("expiration", null);
      localStorage.setItem("roles", null);
      localStorage.setItem("isSuperAdmin", null);
      localStorage.setItem("doctorGuid", null);
      localStorage.setItem("hostName", null);
      axios.defaults.headers.get["Authorization"] = null;
    },
  },
});
