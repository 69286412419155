import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import { store } from "./store/store";
import i18n from "./i18n";
import "./plugins/base";
import Vue2Filters from "vue2-filters";
import axios from "axios";
import "@mdi/font/css/materialdesignicons.css";
import DatetimePicker from "vuetify-datetime-picker";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import VueSplash from "vue-splash";
import jwt_decode from "jwt-decode";
// import devextreme from "./plugins/devextreme";

var isDark = localStorage.dark;

import(
  `devextreme/dist/css/dx.${
    isDark == true || isDark == "true" ? "dark" : "light"
  }.css`
);

// if (isDark) {
//   import("devextreme/dist/css/dx.dark.css");
// } else {
//   import("devextreme/dist/css/dx.light.css");
// }

// import { VueEditor, Quill } from "vue2-editor";
// import { ImageDrop } from "quill-image-drop-module";
// Quill.register("modules/imageDrop", ImageDrop);
// import "quill/dist/quill.snow.css"; // for snow theme
// // import { VueReCaptcha } from "vue-recaptcha-v3";
// //import ImageResize from "quill-image-resize-module";

// // For more options see below
// // Vue.use(VueReCaptcha, { siteKey: "6Le1PHEgAAAAAHVRPwmaGTjTQSBCs6MhYeGP7MV3" });
// Vue.use(VueEditor, {
//   modules: {
//     imageDrop: true,
//   },
// });

Vue.use(VueSplash);

const moment = require("moment");
moment.locale(localStorage.getItem("lang") == "en" ? "en" : "ar-dz");
Vue.use(require("vue-moment"), { moment });

Vue.use(Toast, {
  position: localStorage.rtl === "true" ? "bottom-left" : "bottom-right",
  timeout: 3000,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  closeButton: "button",
  icon: true,
  rtl: localStorage.rtl === "true",
});

Vue.use(DatetimePicker);
Vue.use(Vue2Filters);
Vue.mixin({
  data() {
    return {
      dateNow: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      doctorGuid: localStorage.doctorGuid,
    };
  },

  computed: {},
  methods: {
    isInRole(role) {
      try {
        //var decode = jwt_decode(localStorage.token);

        return jwt_decode(localStorage.token).role.some((s) => s == role);
      } catch (error) {
        console.log(error);
        return false;
      }
    },
  },
});

Vue.config.devtools = false;

Vue.config.productionTip = false;

axios.defaults.withCredentials = true;

// if (axios.defaults.baseURL === "" || axios.defaults.baseURL === undefined) {
//   axios.get("/static/config.json").then((res) => {
//     var hostName = localStorage.getItem("hostName");
//     axios.defaults.baseURL = res.data.VUE_APP_URL.replace("{0}", hostName);
//     console.log(axios.defaults.baseURL);
//   });
// } else {
// }
// console.log(window.location);

axios.defaults.baseURL = "http://" + window.location.host + "/api/api/";
// axios.defaults.baseURL = "http://ali.local:5001/api/";
// console.log(axios.defaults.baseURL);

axios.defaults.headers.get.Accepts = "application/json";
axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
axios.interceptors.request.use(
  (config) => {
    store.commit("overlay", true);

    const token = localStorage.getItem("token");
    const auth = token ? `Bearer ${token}` : "";
    config.headers.common.Authorization = auth;
    return config;
  },
  (error) => Promise.reject(error)
);

axios.interceptors.response.use(
  function (response) {
    store.commit("overlay", false);

    return response;
  },
  function (error) {
    store.commit("overlay", false);
    if (
      error.response === undefined ||
      error.response.status === 401 ||
      error.response.status === 403
    ) {
      router.push({ name: "notauthorize" });
    }
    return Promise.reject(error);
  }
);

Vue.prototype.$axios = axios;

// import { TiptapVuetifyPlugin } from "tiptap-vuetify";
// import "tiptap-vuetify/dist/main.css";

// Vue.use(TiptapVuetifyPlugin, {
//   // the next line is important! You need to provide the Vuetify Object to this place.
//   vuetify, // same as "vuetify: vuetify"
//   // optional, default to 'md' (default vuetify icons before v2.0.0)
//   iconsGroup: "mdi",
// });

// System.Import(`devextreme/dist/css/dx.${isDark ? "dark" : "light"}.css`);

new Vue({
  store: store,
  router,
  i18n,
  // devextreme,
  vuetify,
  axios,
  icons: {
    iconfont: "mdi", // default - only for display purposes
  },
  // directives: {
  //   print,
  // },
  render: (h) => h(App),
}).$mount("#app");
